<template>
  <el-container>
    <el-header style="padding: 0" height="190px">
      <template>
        <heard></heard>
      </template>
    </el-header>
    <el-main style="padding: 0">
      <template>
        <div class="basic_container">
          <el-divider>联系信息</el-divider>
          <div class="pstyle">
            <p v-if="companyInfo.companyAddress">地址：{{ companyInfo.companyAddress }}</p>
            <p v-if="companyInfo.companyPhone">电话：{{ companyInfo.companyPhone }}</p>
            <p v-if="companyInfo.companyFax">传真：{{ companyInfo.companyFax }}</p>
            <p v-if="companyInfo.companyEmail">邮箱：{{ companyInfo.companyEmail }}</p>
            <p v-if="companyInfo.companyUrl">网址：<a :href="companyInfo.companyUrl">{{ companyInfo.companyUrl }}</a>
            </p>
          </div>
        </div>
        <div class="basic_container">
          <el-divider>加我微信</el-divider>
          <div class="wechat-add">
            <el-image :src="require('@/assets/wechat/me.png')"></el-image>
            <el-image :src="require('@/assets/wechat/bgzh.png')"></el-image>
          </div>

        </div>

      </template>
      <template>
        <baidu-map-components class="basic_container_noflex" :map="map"
                              @select-location="selectLocation"></baidu-map-components>
      </template>
      <template>
        <top-tag></top-tag>
      </template>
      <template>
        <top-message></top-message>
      </template>
    </el-main>
  </el-container>
</template>

<script>
import BaiduMapComponents from "@/components/baiduMap";
import Heard from "@/components/Heard";
import TopMessage from "@/components/TopMessage";
import TopTag from "@/components/TopTag";
import {httpAction} from "@/api/manage";

export default {
  name: "LinkUs",
  components: {BaiduMapComponents, Heard, TopMessage, TopTag},
  data() {
    return {
      formForAdd: {},
      map: {
        center: {lng: 104.122761, lat: 30.945554},
        zoom: 15,
        show: true,
        dragging: true,
        isAdd: false,
        keyword: "",
      },
      label_items: [],
      urls: {
        initIndex: "/index/getIndexConfig",
        companyInfo: "/companyManage/getCompanyInfo"
      },
      companyInfo: {}
    }
  },
  created() {
    httpAction(this.urls.companyInfo, {body: {}}).then(res => {
      if (res.code == 0) {
        this.companyInfo = Object.assign({}, res.data)
        this.loadLabelItems()
      }
    })
  },
  methods: {
    selectLocation: function (e) {
      // 这里用到了antDesign，不再科普了。实际上就是获取子组件传来的数据
      // this.formForAdd.setFieldsValue({
      //   longitude: e.lng,
      //   latitude: e.lat,
      // })
    },
    loadLabelItems() {
      this.label_items = [
        {
          text: "ADDRESS",
          text_Context: this.companyInfo.companyAddress,
          icon: require("@/assets/address_icon.png"),
          border_left: true,
        }, {
          text: "CALL US",
          text_Context: "电话:" + this.companyInfo.companyPhone,
          icon: require("@/assets/phone_icon.png"),
          border_left: true,
        },
        {
          text: "EMAIL",
          text_Context: this.companyInfo.companyEmail,
          icon: require("@/assets/email_icon.png"),
          border_left: false,
        }
      ]
    }
  },
}
</script>

<style scoped>
.basic_container {
  width: 1080px;
  display: flex;
  position: relative;
  flex-wrap: wrap;
  margin: 10px auto;
}

.pstyle {
  text-align: left;
}

.basic_container_noflex {
  width: 1080px;
  position: relative;
  margin: 10px auto;
}

.border_left_line {
  /*border-right: 1px solid gray;*/
}

.wechat-add {
  display: flex;
  flex-direction: row;

}
</style>
