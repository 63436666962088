<template>
  <div>
    <el-form v-if="map.isSearch">
      <el-row :gutter="16">
        <el-col :span="12">
          <el-form-item>
            <el-input v-model="keyword" style="display: inline-block"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="3">
          <el-button @click="clickTo" style="display: inline-block" type="primary" icon="el-icon-search">搜索</el-button>
        </el-col>
      </el-row>
    </el-form>
    <baidu-map class="map" :style="{width:map.width,height: map.height}" :center="map.center" :zoom="map.zoom"
               @ready="handler">

      <bm-local-search style="height: 200px;width: 400px;position: absolute;top: 0" v-if="map.isAdd" class="search"
                       :keyword="map.keyword" :auto-viewport="true"
                       :location="map.location"></bm-local-search>
      <bm-navigation anchor="BMAP_ANCHOR_TOP_LEFT"></bm-navigation>
      <bm-marker :position="map.center" :dragging="map.dragging" animation="BMAP_ANIMATION_DROP">
        <bm-info-window :show="map.show">四川省成都市彭州万贯服装产业员B2区55栋</bm-info-window>
      </bm-marker>
      <bm-geolocation anchor="BMAP_ANCHOR_BOTTOM_RIGHT" :showAddressBar="true" :autoLocation="true"></bm-geolocation>
    </baidu-map>
  </div>
</template>

<script>

export default {
  name: "simple-map",
  props: {
    map: {
      type: Object,
      default: {
        isSearch: true,
        width: '100%',
        height: '600px',
      }
    }
  },
  data() {
    return {
      keyword: "四川省成都市彭州万贯服装产业员B2区55栋",
    }
  },
  methods: {
    handler({BMap, map}) {
      let me = this;
      map.enableScrollWheelZoom(true);
      // 点击事件获取经纬度
      map.addEventListener('click', function (e) {
        me.$emit('select-location', {
          lng: e.point.lng,
          lat: e.point.lat
        });
      })
    },
    clickTo() {
      this.$set(this.map, "keyword", this.keyword)
      // this.map.isAdd=false
    }

  }
}
</script>

<style scoped>
.map {
  width: 100%;
  height: 600px;
}

.map .search {
  margin-bottom: 65px;
}
</style>
